import { Login } from 'components/login/Login';
import { useAuth } from 'context';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import AppHead from 'utls/AppHead';

const Page = () => {
  const { token } = useAuth();
  const router = useRouter();
  useEffect(() => {
    if (token) router.push('/');
  }, [token]);
  return (
    <React.Fragment>
      <AppHead title={'تسجل الدخول'} />
      <Login />
    </React.Fragment>
  );
};

export default Page;
