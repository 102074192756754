import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  fontSize?: string;
  title?: string;
  type?: string;
  subtext?: string;
  children?: any;
}

const SectionHeading = ({
  fontSize,
  title,
  type,
  subtext,
  py,
  textAlign
}: Props) => {
  return (
    <React.Fragment>
      <Flex
        textAlign={'center'}
        position={'relative'}
        width={'fit-content'}
        // m='0px auto'
        mx='auto'
        my={py}
      >
        <Heading
          as={type}
          fontSize={fontSize ? fontSize : null}
          textAlign={textAlign}
        >
          {title}
        </Heading>
        <Box
          as='svg'
          width='30px'
          height='30px'
          viewBox='0 0 30 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          transition='all 250ms'
          fontSize={fontSize}
          position={'absolute'}
          top='-5px'
          right={'-20px'}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.36465 23.3299C5.97752 22.7184 3.47826 22.41 1.05382 22.1274C0.531638 22.0682 0.0468232 22.3987 0.00952416 22.8645C-0.0650739 23.3305 0.308135 23.7568 0.793022 23.816C3.14286 24.088 5.56708 24.3791 7.84232 24.9677C8.32721 25.0954 8.84961 24.8326 8.99881 24.3805C9.14801 23.9287 8.84954 23.4579 8.36465 23.3299Z'
            fill='black'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6004 14.6612C11.7586 11.0828 7.43186 7.96497 3.66466 4.29055C3.32897 3.94738 2.73218 3.929 2.35919 4.24936C1.9862 4.57005 1.94905 5.10932 2.32204 5.45249C6.08924 9.13745 10.4159 12.2655 14.2577 15.8544C14.6307 16.1894 15.2274 16.1942 15.6004 15.8646C15.936 15.5354 15.9733 14.9962 15.6004 14.6612Z'
            fill='black'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M22.7996 0.897329C22.9115 2.94544 23.0231 4.99355 23.135 7.04201C23.135 7.51114 23.5828 7.87337 24.105 7.85056C24.6272 7.82741 25 7.42807 25 6.9586C24.8881 4.90708 24.7764 2.8559 24.6645 0.804728C24.6272 0.335595 24.1796 -0.0242543 23.6574 0.00127899C23.1725 0.0268123 22.7623 0.428538 22.7996 0.897329Z'
            fill='black'
          />
        </Box>
      </Flex>
      {subtext ? <Text m='0px auto'>{subtext}</Text> : null}
    </React.Fragment>
  );
};

export default SectionHeading;
